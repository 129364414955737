import React from 'react'

// Images
import Tetris1 from 'img/tetris1.inline.svg'
import Tetris2 from 'img/tetris2.inline.svg'
import Tetris3 from 'img/tetris3.inline.svg'
import Tetris4 from 'img/tetris4.inline.svg'
import Tetris5 from 'img/tetris5.inline.svg'
import Tetris6 from 'img/tetris6.inline.svg'
import Tetris7 from 'img/tetris7.inline.svg'

// Third Party
import styled from 'styled-components'

const StyledTetrisHome = styled.section`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
`

// LEFT
const StyledTetrisFirstLeft = styled.div`
  position: absolute;
  left: -60px;
  top: 95px;
`

const StyledTetrisSecondLeft = styled.div`
  position: absolute;
  left: -120px;
  top: 775px;
`

const StyledTetrisThirdLeft = styled.div`
  position: absolute;
  left: -40px;
  top: 1413px;

  @media (max-width: 991px) {
    top: 1280px;
  }

  @media (max-width: 575px) {
    top: 920px;
    left: -210px;
  }
`

const StyledTetrisFourthLeft = styled.div`
  position: absolute;
  left: -40px;
  top: 2830px;

  @media (max-width: 991px) {
    top: 3080px;
  }

  @media (max-width: 575px) {
    top: 2930px;
    left: -120px;
  }
`

// RIGHT
const StyledTetrisFirstRight = styled.div`
  position: absolute;
  right: -60px;
  top: 675px;

  @media (max-width: 991px) {
    top: 400px;
  }

  @media (max-width: 575px) {
    top: 180px;
    right: -150px;
  }
`

const StyledTetrisSecondRight = styled.div`
  position: absolute;
  right: -40px;
  top: 1415px;

  @media (max-width: 991px) {
    top: 1250px;
  }

  @media (max-width: 575px) {
    top: 980px;
    right: -140px;
  }
`

const StyledTetrisThirdRight = styled.div`
  position: absolute;
  right: -40px;
  top: 2250px;

  @media (max-width: 991px) {
    top: 2020px;
  }

  @media (max-width: 575px) {
    top: 1760px;
    right: -160px;
  }
`

const StyledTetrisFourthRight = styled.div`
  position: absolute;
  right: -40px;
  top: 3400px;

  @media (max-width: 991px) {
    top: 3530px;
  }

  @media (max-width: 575px) {
    top: 3320px;
    right: -160px;
  }
`

const TetrisHome = () => (
  <StyledTetrisHome>
    {/* LEFT */}
    <StyledTetrisFirstLeft>
      <Tetris2 />
    </StyledTetrisFirstLeft>
    <StyledTetrisSecondLeft>
      <Tetris5 />
    </StyledTetrisSecondLeft>
    <StyledTetrisThirdLeft>
      <Tetris4 />
    </StyledTetrisThirdLeft>
    <StyledTetrisFourthLeft>
      <Tetris1 />
    </StyledTetrisFourthLeft>

    {/* RIGHT */}
    <StyledTetrisFirstRight>
      <Tetris6 />
    </StyledTetrisFirstRight>
    <StyledTetrisSecondRight>
      <Tetris3 />
    </StyledTetrisSecondRight>
    <StyledTetrisThirdRight>
      <Tetris5 />
    </StyledTetrisThirdRight>
    <StyledTetrisFourthRight>
      <Tetris7 />
    </StyledTetrisFourthRight>
  </StyledTetrisHome>
)

export default TetrisHome
